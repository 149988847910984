import React, { Component } from 'react';

import ThreeListBar1 from '../../assets/three-list-bar-1.svg';
import ThreeListBar2 from '../../assets/three-list-bar-2.svg';

import Fade from 'react-reveal/Fade';

class ThreeListBlock extends Component {
    render() {
        return (
            <section className="section innerpage-icon-content-block section-with-shape-divider border-0 bg-transparent m-0">
				<div className="shape-divider shape-divider-reverse-x" style={{height: "102px"}}>
                    <img src={ThreeListBar1} alt="Tesic Consulting" />
				</div>
				<div className="shape-divider shape-divider-bottom" style={{height: "102px"}}>
                    <img src={ThreeListBar2} alt="Tesic Consulting" />
				</div>
				<div className="container">
					<div className="row pt-5">
						<div className="col">
							<div className="row mt-3 mb-5">

								<div className="col-md-4">

                                    {this.props.data.headline_1 && 
                                        <Fade bottom>
                                            <h3 className="font-weight-bold text-4 mb-2">
                                                {this.props.data.headline_1}
                                            </h3>
                                        </Fade>
                                    }

                                    {this.props.data.listitems_1 && 
                                        <ul className="list list-icons list-icons-style-3 list-icons-sm">
                                            { this.props.data.listitems_1.map((item, i) => {
                                                return (
                                                    <li key={i}><i className="fas fa-check"></i>
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    }

								</div>

								<div className="col-md-4">

                                    {this.props.data.headline_2 && 
                                        <Fade bottom>
                                            <h3 className="font-weight-bold text-4 mb-2">
                                                {this.props.data.headline_2}
                                            </h3>
                                        </Fade>
                                    }

                                    {this.props.data.listitems_2 && 
                                        <ul className="list list-icons list-icons-style-3 list-icons-sm">
                                            { this.props.data.listitems_2.map((item, i) => {
                                                return (
                                                    <li key={i}><i className="fas fa-check"></i>
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    }
                                    
								</div>

								<div className="col-md-4">

                                    {this.props.data.headline_3 && 
                                        <Fade bottom>
                                            <h3 className="font-weight-bold text-4 mb-2">
                                                {this.props.data.headline_3}
                                            </h3>
                                        </Fade>
                                    }

                                    {this.props.data.listitems_3 && 
                                        <ul className="list list-icons list-icons-style-3 list-icons-sm">
                                            { this.props.data.listitems_3.map((item, i) => {
                                                return (
                                                    <li key={i}><i className="fas fa-check"></i>
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    }

								</div>

							</div>
						</div>
					</div>
				</div>
			</section>
        );
    }
}

export default ThreeListBlock;