import React, { Component } from 'react';

import IconBlock1 from '../../assets/icon-block-1.svg';
import IconBlock2 from '../../assets/icon-block-2.svg';

import Fade from 'react-reveal/Fade';


class IconTextBlock extends Component {
    render() {
        return (
            <section className="section innerpage-icon-content-block section-with-shape-divider border-0 bg-transparent m-0">

				<div className="shape-divider shape-divider-reverse-x" >
                    <img src={IconBlock1} alt="Tesic Consulting" />
				</div>

				<div className="shape-divider shape-divider-bottom" >
                    <img src={IconBlock2} alt="Tesic Consulting" />
				</div>

				<div className="container pb-mobile-0 py-5 my-5">

                    {this.props.data.headline_7 && 
                        <Fade bottom>
                            <div className="row justify-content-center pb-mobile-0 pb-2 mt-2 mb-4">
                                <div className="text-center">
                                    <h2 className="text-color-secondary font-weight-bold text-8 mb-3" >
                                    	{this.props.data.headline_7}
                                    </h2>
                                </div>
                            </div>
                        </Fade>
                    }

					<div className="featured-boxes featured-boxes-style-4 custom-featured-boxes-style-1">
						<div className="row justify-content-center">


							{ ((this.props.data.list_text_1) || (this.props.data.headline_1) || (this.props.data.text_1)) &&
								<div className="col-md-6">
									<div className="featured-box featured-box-primary featured-box-effect-5" >
										<div className="box-content text-center">
										
											{this.props.data.list_text_1 ?
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className={`${this.props.data.list_text_1} img-fluid icon-featured`}></i>
												</div>
												:
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className="fas fa-hands-helping img-fluid icon-featured" ></i>
												</div>
											}

											{this.props.data.headline_1 &&
												<h3 className="text-transform-none font-weight-bold custom-font-size-1 mb-3">
													{this.props.data.headline_1}
												</h3>
											}

											{this.props.data.text_1 &&
												<div className="px-3 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_1}}>
												</div>
											}
						
										</div>
									</div>
								</div>
							}


							{ ((this.props.data.list_text_2) || (this.props.data.headline_2) || (this.props.data.text_2)) &&
								<div className="col-md-6">
									<div className="featured-box featured-box-primary featured-box-effect-5" >
										<div className="box-content text-center">
										
											{this.props.data.list_text_2 ?
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className={`${this.props.data.list_text_2} img-fluid icon-featured`}></i>
												</div>
												:
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className="fas fa-hands-helping img-fluid icon-featured" ></i>
												</div>
											}

											{this.props.data.headline_2 &&
												<h3 className="text-transform-none font-weight-bold custom-font-size-1 mb-3">
													{this.props.data.headline_2}
												</h3>
											}

											{this.props.data.text_2 &&
												<div className="px-3 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_2}}>
												</div>
											}
						
										</div>
									</div>
								</div>
							}

							
							{ ((this.props.data.list_text_3) || (this.props.data.headline_3) || (this.props.data.text_3)) &&
								<div className="col-md-6">
									<div className="featured-box featured-box-primary featured-box-effect-5" >
										<div className="box-content text-center">
										
											{this.props.data.list_text_3 ?
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className={`${this.props.data.list_text_3} img-fluid icon-featured`}></i>
												</div>
												:
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className="fas fa-hands-helping img-fluid icon-featured" ></i>
												</div>
											}

											{this.props.data.headline_3 &&
												<h3 className="text-transform-none font-weight-bold custom-font-size-1 mb-3">
													{this.props.data.headline_3}
												</h3>
											}

											{this.props.data.text_3 &&
												<div className="px-3 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_3}}>
												</div>
											}
						
										</div>
									</div>
								</div>
							}

							{ ((this.props.data.list_text_4) || (this.props.data.headline_4) || (this.props.data.text_4)) &&
								<div className="col-md-6">
									<div className="featured-box featured-box-primary featured-box-effect-5" >
										<div className="box-content text-center">
										
											{this.props.data.list_text_4 ?
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className={`${this.props.data.list_text_4} img-fluid icon-featured`}></i>
												</div>
												:
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className="fas fa-hands-helping img-fluid icon-featured" ></i>
												</div>
											}

											{this.props.data.headline_4 &&
												<h3 className="text-transform-none font-weight-bold custom-font-size-1 mb-3">
													{this.props.data.headline_4}
												</h3>
											}

											{this.props.data.text_4 &&
												<div className="px-3 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_4}}>
												</div>
											}
						
										</div>
									</div>
								</div>
							}

							{ ((this.props.data.list_text_5) || (this.props.data.headline_5) || (this.props.data.text_5)) &&
								<div className="col-md-6">
									<div className="featured-box featured-box-primary featured-box-effect-5" >
										<div className="box-content text-center">
										
											{this.props.data.list_text_5 ?
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className={`${this.props.data.list_text_5} img-fluid icon-featured`}></i>
												</div>
												:
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className="fas fa-hands-helping img-fluid icon-featured" ></i>
												</div>
											}

											{this.props.data.headline_5 &&
												<h3 className="text-transform-none font-weight-bold custom-font-size-1 mb-3">
													{this.props.data.headline_5}
												</h3>
											}

											{this.props.data.text_5 &&
												<div className="px-3 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_5}}>
												</div>
											}
						
										</div>
									</div>
								</div>
							}


							{ ((this.props.data.list_text_6) || (this.props.data.headline_6) || (this.props.data.text_6)) &&
								<div className="col-md-6">
									<div className="featured-box featured-box-primary featured-box-effect-5" >
										<div className="box-content text-center">
										
											{this.props.data.list_text_6 ?
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className={`${this.props.data.list_text_6} img-fluid icon-featured`}></i>
												</div>
												:
												<div className="custom-featured-icon-wrapper bg-secondary mb-4">
													<i className="fas fa-hands-helping img-fluid icon-featured" ></i>
												</div>
											}

											{this.props.data.headline_6 &&
												<h3 className="text-transform-none font-weight-bold custom-font-size-1 mb-3">
													{this.props.data.headline_6}
												</h3>
											}

											{this.props.data.text_6 &&
												<div className="px-3 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_6}}>
												</div>
											}
						
										</div>
									</div>
								</div>
							}





						</div>
					</div>
				</div>
			</section>
        );
    }
}

export default IconTextBlock;