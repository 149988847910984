import React, { Component, useState } from 'react';
import emailjs from 'emailjs-com';

import BannerElement1 from '../../assets/banner_element_1.svg';
import BannerElement2 from '../../assets/banner_element_2.svg';

import Fade from 'react-reveal/Fade';

import Loader from "react-loader-spinner";


const BannerForm = (props) => {

    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);


    function sendEmail(e) {
        setStatusLoader(true);
        e.preventDefault();

        emailjs.sendForm('service_djkxsrw', 'template_zunko3a', e.target, 'user_BI5ioEmtFcuQMMWyG9ycX')
        .then((result) => {
            console.log(result.text);
            setStatusLoader(false);
            setSuccessMessage(true);
            setTimeout(function () {
              setSuccessMessage(false);
            }.bind(this), 5000)
            e.target.reset();

        }, (error) => {
            console.log(error.text);
            setStatusLoader(false);
            setErrorMessage(true);
            setTimeout(function () {
              setErrorMessage(false);
            }.bind(this), 7000)
        });
        
    }
    

    return (
        <div>

            <div className="section banner-form innerpage-header overlay overlay-show overlay-op-3 section-with-shape-divider section-parallax bg-tertiary border-0 m-0"
                style={{ background: `url('${props.data.image_1_path}') no-repeat center top`, WebkitBackgroundSize: 'cover',  MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', height: "80vh"}}
                >

                <div className="shape-divider shape-divider-bottom" >
                    <img src={BannerElement1} className="img-responsive" alt="Tesic Consulting" />
                </div>
                <div className="container position-relative z-index-1 pb-5 my-5">
                    <div>
                        <div className="custom-svg-stars clean-animation-1">
                            <img src={BannerElement2}  alt="Tesic Consulting" />
                        </div>
                    </div>
                    <div className="row justify-content-center justify-content-lg-start pb-5 mb-5">
                        <div className="col-md-10 col-lg-6 text-center text-lg-right pr-lg-4 pb-5 mb-5">
                            <Fade bottom>
                                <h1 className="text-color-light font-weight-medium line-height-4 text-10 mb-2">
                                    {props.data.headline_1 && <span className="font-weight-bold custom-highlight-1 ws-nowrap p-1 custom-highlight-1-primary customHighlightAnim customHighlightAnimDelay">{props.data.headline_1}</span>} {props.data.headline_2}
                                </h1>
                            </Fade>
                            <div className="spacer pt-5 my-5"></div>
                            <div className="spacer pt-5 my-5"></div>
                            <div className="spacer pt-5 my-5"></div>
                        </div>
                    </div>
                </div>
            </div>

            <Fade bottom>
                <div className="container home-form-container position-relative z-index-1" style={{marginTop: "-388px"}} >
                    <div className="row align-items-end pb-4 mb-4 mb-lg-5">
                        <div className="col-lg-6 pr-lg-4 mb-5 mb-lg-0">
                            <div className="card home-header-form border-0 box-shadow-1">
                                <div className="card-body p-5">
                                    <h2 className="text-color-secondary font-weight-bold text-7 line-height-1 mb-1">Rückruf anfordern</h2>
                                    <p className="custom-font-secondary text-4 pb-1 mb-4">Geben Sie Ihre Daten in das Formular ein und wir rufen Sie zurück.</p>
                                    <form className="contact-form custom-form-style-1" onSubmit={sendEmail}>

                                        { successMessage &&
                                            <div className="contact-form-success alert alert-success mt-4">
                                                <strong>Erfolg!</strong> Ihre Anfrage wurde an uns gesendet.
                                            </div>
                                        }

                                        { errorMessage && 
                                            <div className="contact-form-error alert alert-danger mt-4">
                                                <strong>Error!</strong> Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten.
                                                <span className="mail-error-message text-1 d-block"></span>
                                            </div>
                                        }           
                                        
                                        { statusLoader && 
                                            <div id="loader-status">
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#D83F87"
                                                    height={30}
                                                    width={80}
                                                />
                                            </div> 
                                        }

                                        <div className="form-row">
                                            <div className="form-group col pb-1 mb-3">
                                                <input type="text" className="form-control" name="name" placeholder="Name" required />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col pb-1 mb-3">
                                                <input type="number" className="form-control" name="phone" placeholder="Telefonnummer" required />
                                            </div>
                                        </div>
                                        
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <input className="btn btn-primary btn-modern font-weight-bold text-3 px-5 py-3" type="submit" value="RUF MICH ZURÜCK" />
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-lg-5">
                            <ul className="list list-unstyled mb-0">
                                <li className="mb-0">
                                    <span className="d-flex align-items-center justify-content-end justify-content-lg-end flex-column flex-lg-row font-weight-medium text-4 text-lg-3 text-xl-4">
                                        {props.data.email_text}
                                        <strong className="text-3 ml-lg-3">
                                            {props.data.tesic_email &&
                                                <a href={`mailto:${props.data.tesic_email}?subject=Hello%20Tesic%20Consulting`} className="text-color-tertiary text-color-hover-primary" target="_blank">{props.data.tesic_email}</a>
                                            }
                                        </strong>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </Fade>
        </div>
    );
    
}

export default BannerForm;