import React, { Component, useState } from 'react';
import emailjs from 'emailjs-com';

import Fade from 'react-reveal/Fade';
import Loader from "react-loader-spinner";

const ContactForm = (props) => {

	const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);

	function sendEmail(e) {
        setStatusLoader(true);
        e.preventDefault();

        emailjs.sendForm('service_djkxsrw', 'template_z6qvkrb', e.target, 'user_BI5ioEmtFcuQMMWyG9ycX')
        .then((result) => {
            console.log(result.text);
            setStatusLoader(false);
            setSuccessMessage(true);
            setTimeout(function () {
              setSuccessMessage(false);
            }.bind(this), 5000)
            e.target.reset();

        }, (error) => {
            console.log(error.text);
            setStatusLoader(false);
            setErrorMessage(true);
            setTimeout(function () {
              setErrorMessage(false);
            }.bind(this), 7000)
        });
    }
   
	return (

		<section className="contact-form">
			<div className="container py-5 mt-5">
				<div className="row pb-2 mb-4">
					<div className="col">
						<div className="d-flex align-items-center mb-2">
							<span className="custom-line"></span>
							<div className="overflow-hidden ml-3">
								<h2 className="text-color-primary font-weight-semibold line-height-3 text-4 mb-0">Wir freuen uns auf den Kontakt</h2>
							</div>
						</div>
						<h3 className="text-color-secondary font-weight-bold text-transform-none line-height-2 text-8 mb-0" >Nehmen Sie mit uns Kontakt auf</h3>
					</div>
				</div>
				<div className="row pb-5">
					<div className="col">
						<form className="contact-form custom-form-style-1" onSubmit={sendEmail}>

							{ successMessage &&
								<div className="contact-form-success alert alert-success mt-4">
									<strong>Erfolg!</strong> Ihre Anfrage wurde an uns gesendet.
								</div>
							}

							{ errorMessage && 
								<div className="contact-form-error alert alert-danger mt-4">
									<strong>Error!</strong> Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten.
									<span className="mail-error-message text-1 d-block"></span>
								</div>
							}           
							
							{ statusLoader && 
								<div id="loader-status">
									<Loader
										type="ThreeDots"
										color="#D83F87"
										height={30}
										width={80}
									/>
								</div> 
							}

							<div className="form-row row-gutter-sm">
								<div className="form-group col-lg-6 mb-4">
									<input type="text" className="form-control" name="name" placeholder="Name" required />
								</div>
								<div className="form-group col-lg-6 mb-4">
									<input type="number" className="form-control" name="phone" placeholder="Telefon" required />
								</div>
							</div>

							<div className="form-row row-gutter-sm">
								<div className="form-group col-lg-6 mb-4">
									<input type="email" className="form-control" name="email" placeholder="Email" required />
								</div>
								<div className="form-group col-lg-6 mb-4">
									<input type="text" className="form-control" name="subject" placeholder="Betreff" required />
								</div>
							</div>

							<div className="form-row">
								<div className="form-group col mb-4">
									<textarea className="form-control" rows="10" name="message" placeholder="Nachricht" required></textarea>
								</div>
							</div>

							<div className="form-row">
								<div className="form-group col mb-0">
									<input className="btn btn-primary btn-modern font-weight-bold text-3 px-5 py-3" type="submit" value="SENDEN" />
								</div>
							</div>

						</form>
					</div>
				</div>
			</div>
		</section>
	);
   
}

export default ContactForm;