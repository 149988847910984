import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
class ResourcesContent extends Component {
    render() {
        return (
            <section className="resources-content section home-link-block border-0 py-4 m-0">
                <div className="container">
                    <div className="row align-items-end mt-5 mb-5 mb-lg-4">
                        <div className="col-lg-12 mb-lg-0">

                            {this.props.data.headline.value &&
                                <Fade bottom>
                                    <h3 className="text-color-secondary font-weight-bold text-transform-none text-8 mb-3 pb-1">{this.props.data.headline.value}</h3>
                                </Fade>
                            }

                            {this.props.data.text_1 &&
                                <Fade bottom>
                                    <div className="pb-1 mb-3" dangerouslySetInnerHTML={{__html: this.props.data.text_1}}>
                                    </div>
                                </Fade>
                            }

                            {this.props.data.image_1_path &&
                                <img src={this.props.data.image_1_path} alt="Tesic Consulting" /> 
                            }

                        </div>
                        
                    </div>

                </div>

            </section>
        );
    }
}

export default ResourcesContent;