import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { NavLink} from 'react-router-dom';


class ThreeImageTextBlock extends Component {
    render() {
        return (
            <section className="section home-link-block border-0 py-4 m-0">
                <div className="container">
                    
                    <div className="row align-items-end pb-3 mb-5 mb-lg-4">
                       
                        <div className="col-lg-12 mb-4 mb-lg-0">

                            <div className="d-flex align-items-center mb-2">
                                <span className="custom-line"></span>
                                <div className="overflow-hidden ml-3">
                                    <h2 className="text-color-primary font-weight-semibold line-height-3 text-4 mb-0">{this.props.data.headline_4}</h2>
                                </div>
                            </div>

                            <h3 className="text-color-secondary font-weight-bold text-transform-none text-8 mb-3 pb-1">{this.props.data.headline_5}</h3>
                            
                            <Fade bottom>
                                <div className="mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_4}}>
                                </div>
                            </Fade>

                        </div>
                        
                    </div>

                    <div className="row">

                        <div className="col-lg-4 mb-5">
                            <NavLink to={this.props.data.link_1.split('.').slice(0, -1).join('.')} className="text-decoration-none">
                                <div className="card custom-card-style-1">
                                    <div className="card-body text-center py-5">
                                        <div className="custom-card-style-1-image-wrapper bg-primary rounded-circle d-inline-block mb-3">
                                            <img src={this.props.data.image_1_path} className="img-fluid rounded-circle" alt="Betreuen" />
                                        </div>
                                        <h4 className="custom-card-style-1-title text-color-secondary font-weight-bold mb-2">
                                            {this.props.data.headline_1}
                                        </h4>
                                        <Fade bottom>
                                            <div className="custom-card-style-1-description" dangerouslySetInnerHTML={{__html: this.props.data.text_1}}>
                                            </div>
                                        </Fade>
                                        <span className="custom-card-style-1-link font-weight-bold">{this.props.data.link_text_1}</span>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4 mb-5">
                            <NavLink to={this.props.data.link_2.split('.').slice(0, -1).join('.')} className="text-decoration-none">
                                <div className="card custom-card-style-1">
                                    <div className="card-body text-center py-5">
                                        <div className="custom-card-style-1-image-wrapper bg-primary rounded-circle d-inline-block mb-3">
                                            <img src={this.props.data.image_2_path} className="img-fluid rounded-circle" alt="" />
                                        </div>
                                        <h4 className="custom-card-style-1-title text-color-secondary font-weight-bold mb-2">
                                            {this.props.data.headline_2}
                                        </h4>
                                        <Fade bottom>
                                            <div className="custom-card-style-1-description" dangerouslySetInnerHTML={{__html: this.props.data.text_2}}>
                                            </div>
                                        </Fade>
                                        <span className="custom-card-style-1-link font-weight-bold">{this.props.data.link_text_2}</span>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4 mb-5">
                            <NavLink to={this.props.data.link_3.split('.').slice(0, -1).join('.')} className="text-decoration-none">
                                
                                <div className="card custom-card-style-1">
                                    <div className="card-body text-center py-5">
                                        <div className="custom-card-style-1-image-wrapper bg-primary rounded-circle d-inline-block mb-3">
                                            <img src={this.props.data.image_3_path} className="img-fluid rounded-circle" alt="" />
                                        </div>
                                        <h4 className="custom-card-style-1-title text-color-secondary font-weight-bold mb-2">
                                            {this.props.data.headline_3}
                                        </h4>
                                        <Fade bottom>
                                            <div className="custom-card-style-1-description" dangerouslySetInnerHTML={{__html: this.props.data.text_3}}>
                                            </div>
                                        </Fade>
                                        <span className="custom-card-style-1-link font-weight-bold">{this.props.data.link_text_3}</span>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default ThreeImageTextBlock;