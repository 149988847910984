import React, { useState, useEffect } from 'react';
import { NavLink} from 'react-router-dom';
import api from '../data/api'
import phoneElement from '../../assets/phone.svg';

const Footer = () => {

	const [isLoading, setIsLoading] = useState(true);
    const [resourcesData, setresourcesData] = useState();

	const resources = "resources";

	useEffect(() => {

		const fetchPageData = async () => {
            api.getPageDetails(resources)
            .then((response)=>{
				setresourcesData(response.data);
				setIsLoading(false);
            })
            .catch(function (error) {
                console.log("Error", error);
            })
        }
        fetchPageData();

    }, [resources]);


	if(!isLoading) {

		return (

            <footer id="footer" className="bg-color-secondary border-0 mt-0" >
				<div className="container container-lg-custom pt-4 pb-3">
					<div className="row py-5">

						<div className="col-md-3 mb-4 mb-lg-0">

							{resourcesData.articles.main[0].content[0].headline.value ?
								<h4 className="font-weight-bold ls-0">{resourcesData.articles.main[0].content[0].headline.value}</h4>
								:
								<h4 className="font-weight-bold ls-0">Adresse</h4>
							}

							{resourcesData.articles.main[0].content[0].text &&
								<div dangerouslySetInnerHTML={{__html: resourcesData.articles.main[0].content[0].text}}>
								</div>
							}

							<div className="mt-3">

								<h4 className="font-weight-bold ls-0">Folge uns</h4>

								<ul className="social-icons social-icons-clean social-icons-medium">
									{resourcesData.articles.main[0].content[1].text &&
										<li className="social-icons-linkedin icon-color-white">
											<a href={resourcesData.articles.main[0].content[1].text.replace(/<[^>]+>/g, '')} target="_blank" rel="noreferrer">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									}

									{resourcesData.articles.main[0].content[2].text &&
										<li className="social-icons-xing icon-color-white">
											<a href={resourcesData.articles.main[0].content[2].text.replace(/<[^>]+>/g, '')} target="_blank" rel="noreferrer">
												<i className="fab fa-xing"></i>
											</a>
										</li>
									}

									{resourcesData.articles.main[0].content[3].text &&
										<li className="social-icons-whatsapp icon-color-white">
											<a href={`https://wa.me/41${resourcesData.articles.main[0].content[3].text.replace(/<[^>]+>/g, '')}`} target="_blank" rel="noreferrer">
												<i className="fab fa-whatsapp"></i>
											</a>
										</li>
									}

								</ul>
							</div>


						</div>

						<div className="col-md-3 mb-4 mb-lg-0">
							<h4 className="font-weight-bold ls-0">Kontakt</h4>
							
							{resourcesData.articles.main[0].content[4].text &&
								<div className="feature-box feature-box-style-2 align-items-center mb-3">
									<div className="feature-box-icon">
										<img src={phoneElement} style={{height: "45px"}} alt="Tesic Consulting" />
									</div>
									<div className="feature-box-info pl-2">
										<p className="text-uppercase font-weight-semibold line-height-1 text-2 pb-0 mb-0">Ruf uns jetzt an</p>
										<a href={`tel:${resourcesData.articles.main[0].content[4].text.replace(/<[^>]+>/g, '')}`} target="_blank" rel="noreferrer" className="text-uppercase text-color-light text-color-hover-secondary text-decoration-none text-5 font-weight-bold pb-0">{resourcesData.articles.main[0].content[4].text.replace(/<[^>]+>/g, '')}</a>
									</div>
								</div>
							}

							{resourcesData.articles.main[0].content[5].text &&
								<ul className="list-unstyled mb-0">
									<li className="mb-1">
										<a href={`mailto:${resourcesData.articles.main[0].content[5].text.replace(/<[^>]+>/g, '')}`} className="boxed-bg-hover" target="_blank" rel="noreferrer">{resourcesData.articles.main[0].content[5].text.replace(/<[^>]+>/g, '')}</a>
									</li>
								</ul>
							}

						</div>

						<div className="col-md-3 mb-4 mb-md-0">
							<h4 className="font-weight-bold ls-0">Leistungen</h4>
							<ul className="list-unstyled mb-0">
								<li className="mb-1">
									<NavLink to="/medizinische-betreuung" className="boxed-bg-hover">
										Medizinische Betreuung
									</NavLink>
								</li>
								<li className="mb-1">
									<NavLink to="/freizeitgestaltung" className="boxed-bg-hover">
										Freizeitgestaltung
									</NavLink>
								</li>
								<li className="mb-1">
									<NavLink to="/organisieren" className="boxed-bg-hover">
										Organisieren
									</NavLink>
								</li>
							</ul>
						</div>

						<div className="col-md-3 mb-4 mb-md-0">

							{((resourcesData.articles.main[0].content[6]) && (resourcesData.articles.main[0].content[6].headline.value) ) &&
								<h4 className="font-weight-bold ls-0">{resourcesData.articles.main[0].content[6].headline.value}</h4>
							}
							{ ((resourcesData.articles.main[0].content[6]) && (resourcesData.articles.main[0].content[6].text))  &&
								<div dangerouslySetInnerHTML={{__html: resourcesData.articles.main[0].content[6].text}}>
								</div>
							}

							{((resourcesData.articles.main[0].content[7]) && (resourcesData.articles.main[0].content[7].headline.value))  &&
								<h4 className="font-weight-bold ls-0">{resourcesData.articles.main[0].content[7].headline.value}</h4>
							}
							{((resourcesData.articles.main[0].content[7]) && (resourcesData.articles.main[0].content[7].text))  &&
								<div dangerouslySetInnerHTML={{__html: resourcesData.articles.main[0].content[7].text}}>
								</div>
							}


						</div>

					</div>
				</div>

				<div className="footer-copyright bg-color-secondary">
					<div className="container container-lg-custom pb-4">
						<div className="row">
							<div className="col">
								<hr className="my-0 bg-color-light opacity-2" /> 
							</div>
						</div>
						<div className="row py-4 mt-2">
							<div className="col-lg-6 text-center text-lg-left mb-3 mb-lg-0">
								<NavLink to="/home" className="text-decoration-none footer-logo-head">
									<img alt="Tesic" style={{width: "auto", height: "32px"}}  src="files/img/tesic/tesic_consulting_head_white.png" />
								</NavLink>
							</div>
							<div className="col-lg-6 text-center text-lg-right">
								<p className="text-3 mb-0">  Tesic Consulting &copy; <span id="copy-right-year">{(new Date().getFullYear())}</span> Alle Rechte vorbehalten <span>|</span> <a href="https://www.webzenz.ch/" className="boxed-bg-hover" target="_blank" rel="noreferrer"> Webagentur </a>       </p>
							</div>
						</div>
					</div>
				</div>

			</footer>

        

    	);
	} else {
		return(
            <div>
                
            </div>
        )
	}
    


        
}

export default Footer;