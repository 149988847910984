import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import AboutBarTop from '../../assets/about-bar-top.svg';

class AboutBlock extends Component {
    render() {
        return (
            <section className="section home-about-section section-with-shape-divider bg-transparent border-0 m-0">
                <div className="shape-divider shape-divider-bottom" style={{height: "102px"}}>
                    <img src={AboutBarTop} alt="Tesic Consulting" />
                </div>
                <div className="container mt-2 pb-5">
                    <div className="row align-items-center mb-4">
                        <div className="col-lg-5 mb-5 mb-lg-0">

                            {this.props.data.headline_1 && 
                                <Fade bottom>
                                    <div className="d-flex align-items-center mb-2">
                                        <span className="custom-line"></span>
                                        <div className="overflow-hidden ml-3">
                                            <h2 className="text-color-primary font-weight-semibold line-height-3 text-4 mb-0">
                                                {this.props.data.headline_1}
                                            </h2>
                                        </div>
                                    </div>
                                </Fade>
                            }

                            {this.props.data.headline_2 && 
                                <Fade bottom>
                                    <h3 className="text-color-secondary font-weight-bold text-transform-none line-height-2 text-10 mb-2">
                                        {this.props.data.headline_2}
                                    </h3>
                                </Fade>
                            }

                            {this.props.data.headline_3 &&
                                
                                    <Fade bottom>
                                        <p className="custom-font-secondary text-4 mb-2">
                                            {this.props.data.headline_3}
                                        </p>
                                    </Fade>
                                
                            }

                            {this.props.data.text_1 &&
                                <Fade bottom>
                                    <div className="pb-1 mb-3" dangerouslySetInnerHTML={{__html: this.props.data.text_1}}>
                                    </div>
                                </Fade>
                            }

                            {this.props.data.link_1 &&
                                <Fade bottom>
                                    <a href={this.props.data.link_1} className="btn btn-primary btn-modern font-weight-bold text-3 px-5 py-3">{this.props.data.link_text_1}</a>
                                </Fade>
                             }
                            
                        </div>
                        <div className="col-sm-10 image-block col-lg-6 offset-sm-1 pl-sm-5 pl-lg-0">
                            <div className="position-relative">
                                {this.props.data.image_2_path &&
                                    <div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}">
                                        <Fade bottom>
                                            <img src={this.props.data.image_2_path} className="img-fluid rounded-circle custom-box-shadow-1"  alt="" />
                                        </Fade>
                                    </div>
                                }

                                {this.props.data.image_1_path &&
                                    <div className="position-absolute top-50pct left-50pct transform3dxy-n50" style={{left: "25%"}}>
                                        <Fade bottom>
                                            <div data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 0.3, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}">
                                                <img src={this.props.data.image_1_path} className="img-fluid rounded-circle custom-box-shadow-1" alt="" />
                                            </div>
                                        </Fade>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutBlock;