import axios from 'axios'

// Create instance called instance
const instance = axios.create({
    baseURL: 'https://local.tesic-consulting.ch/api',
    headers: { }
});

export default {


    getSitemap: () =>
    instance({
        'method':'GET',
        'url':'/sitemap',
    }),

    getPageDetails: (urlName) =>
    instance({
        'method':'GET',
        'url':`/page?url=${urlName}.html`
    }),
    
    getContentById: (id) =>
    instance({
        'method':'GET',
        'url':`/module?=${id}`
    })

}