import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import BgBanner1 from '../../assets/bg-banner-1.svg';
import BgBanner2 from '../../assets/bg-banner-2.svg';

class BackgroundBannerBlock extends Component {
    render() {
        return (
            <section className="section background-banner-block section-with-shape-divider section-height-3 overlay overlay-show overlay-color-tertiary border-0 m-0" style={{height: "800px", backgroundImage:`url(${this.props.data.image_1_path})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                
                <div className="shape-divider" style={{height: "116px"}}>
                    <img src={BgBanner1} alt="Tesic Consulting" />
                </div>

                <div className="shape-divider shape-divider-bottom" style={{height: "102px"}}>
                    <img src={BgBanner2} alt="Tesic Consulting" />
                </div>

                <div className="container pt-15 pb-5 my-5">
                    <div className="row align-items-center justify-content-center pt-3">
                        <div className="text-center text-xl-left mb-4 mb-xl-0">
                            
                            { ((this.props.data.headline_1) || (this.props.data.headline_2) || (this.props.data.headline_3)) &&
                                <h2 className="text-color-light font-weight-medium line-height-4 text-9 negative-ls-1 mb-2">{this.props.data.headline_1} {this.props.data.headline_2 && <span className="font-weight-bold custom-highlight-1 ws-nowrap custom-highlight-1-primary p-2">{this.props.data.headline_2} </span>} {this.props.data.headline_3}</h2>
                            }

                            {this.props.data.text_1 &&
                                <Fade bottom>
                                    <div className="custom-font-secondary custom-font-size-1 font-weight-light text-color-light opacity-8 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_1}}>
                                    </div>
                                </Fade>
                            }

                        </div>
                    </div>
                </div>

			</section>
        );
    }
}

export default BackgroundBannerBlock;