import React, { useState, useEffect } from 'react';
import {NavLink } from 'react-router-dom';

import phoneElement from '../../assets/phone.svg';
import api from '../data/api'

import Loader from "react-loader-spinner";

const Header = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [sitemapData, setsitemapData] = useState();

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const CloseNavCollapse = () => setIsNavCollapsed(true);


    const [resourcesData, setresourcesData] = useState();
	const resources = "resources";

    useEffect(() => {

        const fetchPageData = async () => {

            api.getSitemap()
            .then((response)=>{
                setsitemapData(response.data);
                setIsLoading(false);
                
            }).catch(function (error) {
                console.log("Error", error);
            })


            api.getPageDetails(resources)
            .then((response)=>{
				setresourcesData(response.data);
            }).catch(function (error) {
                console.log("Error", error);
            })

        }

        fetchPageData();

    }, []);


    if(!isLoading) {

        return (

            <header id="header" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 85}">
                <div className="header-body border-0 ">
                    <div className="header-top header-top-default border-bottom-0">
                        <div className="container">
                            <div className="header-row py-2">
                                <div className="header-column justify-content-end">
                                    <div className="header-row">

                                        {((resourcesData) && (resourcesData.articles.main[0].content[4].text)) &&
                                            <div className="feature-box feature-box-style-2 align-items-center ml-lg-4">
                                                <div className="feature-box-icon d-none d-sm-inline-flex">
                                                    <img src={phoneElement} style={{height: "48px"}} alt="Tesic Consulting" />
                                                </div>
                                                <div className="feature-box-info pl-2">
                                                    <p className="font-weight-semibold line-height-1 text-2 pb-0 mb-1">Rufen Sie uns jetzt an:</p>
                                                    <a href={`tel:${resourcesData.articles.main[0].content[4].text.replace(/<[^>]+>/g, '')}`} target="_blank" rel="noreferrer"  className="text-color-tertiary text-color-hover-primary text-decoration-none font-weight-bold line-height-1 custom-font-size-1 pb-0">{resourcesData.articles.main[0].content[4].text.replace(/<[^>]+>/g, '')}</a>
                                                </div>
                                            </div>
                                        }
                                
                                        <ul className="header-social-icons social-icons d-sm-block social-icons-clean social-icons-icon-light">

                                            {((resourcesData) && (resourcesData.articles.main[0].content[1].text)) &&
                                                <li className="social-icons-linkedin">
                                                    <a href={resourcesData.articles.main[0].content[1].text.replace(/<[^>]+>/g, '')} target="_blank" rel="noreferrer"  >
                                                        <i className="fab fa-linkedin-in"></i>
                                                    </a>
                                                </li>
                                            }

                                            {((resourcesData) && (resourcesData.articles.main[0].content[2].text)) &&
                                                <li className="social-icons-xing">
                                                    <a href={resourcesData.articles.main[0].content[2].text.replace(/<[^>]+>/g, '')} target="_blank" rel="noreferrer" >
                                                        <i className="fab fa-xing"></i>
                                                    </a>
                                                </li>
                                            }

                                            {((resourcesData) && (resourcesData.articles.main[0].content[3].text)) &&
                                                <li className="social-icons-whatsapp">
                                                    <a href={`https://wa.me/41${resourcesData.articles.main[0].content[3].text.replace(/<[^>]+>/g, '')}`} target="_blank" rel="noreferrer"  >
                                                        <i className="fab fa-whatsapp"></i>
                                                    </a>
                                                </li>
                                            }

                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-nav-bar header-nav-bar-top-border bg-light">
                        <div className="header-container container">
                            <div className="header-row">
                                <div className="header-column">
                                    <div className="header-row">
                                        <div className="header-logo">
                                            <NavLink to="/home">
                                                <img src="files/img/tesic/tesic_consulting_logo.png" className="img-fluid" width="183" height="50" alt="tesic consulting logo" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="header-column">
                                    <div className="header-row justify-content-end">
                                        <div className="header-nav p-0">
                                            <div className="header-nav header-nav-line header-nav-divisor header-nav-spaced justify-content-lg-center">
                                                <div className="header-nav-main header-nav-main-square header-nav-main-effect-1 header-nav-main-sub-effect-1">

                                                    <nav className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} >

                                                        { sitemapData.map((item, index) => {
                                                            return (
                                                                <ul className="nav nav-pills flex-column flex-lg-row" id="mainNav" key={item.id}>
                                                                    { item.subPages.map((subItem, subIndex) => {
                                                                        if(!subItem.hide) {
                                                                            return (
                                                                                <li key={subIndex} >
                                                                                    <NavLink className="nav-link" to={subItem.alias} activeClassName="active" onClick={CloseNavCollapse}>
                                                                                        {subItem.title}
                                                                                    </NavLink>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    })}
                                                                </ul>
                                                            );
                                                        })}

                                                    </nav>
                                                    
                                                </div>

                                                <button className="btn header-btn-collapse-nav" type="button" data-toggle="collapse" data-target=".header-nav-main nav" aria-controls="mainNav" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                                    <i className="fas fa-bars"></i>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        );

    } else {
        return(
            <Loader
            type="ThreeDots"
            color="#D83F87"
            height={60}
            width={60}
            className="container vertical-center"
            timeout={8000} //8 secs
            />
        )

    }
     

}

export default Header;