import React, { Fragment, useEffect } from 'react';

import { Transition, TransitionGroup } from "react-transition-group";
import { play, exit } from './TransitionPlay';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';

import CookieConsent from "react-cookie-consent";

import '../../assets/transition-styles.css';

const PageContent = ({ match }) => {
  
  
  const [scrolled,setScrolled]=React.useState(false);
  
  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 80 ){
        setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }
  
  let {
    params: { alias },
  } = match;

  if(alias === undefined) {
    alias = 'home'
  }

  useEffect(() => {
    window.addEventListener('scroll',handleScroll)
  })
  
  return (
    <Fragment>
      <div className={scrolled ? "body sticky-header-active" : "body"}>
        <Header />
        <div role="main" className="main" style={{minHeight: "100vh"}}>
          <TransitionGroup>
            <Transition
                    key={alias}
                    appear={true}
                    onEnter={(node, appears) => play( node, appears)}
                    onExit={(node, appears) => exit(node, appears)}
                    timeout={{enter: 300, exit: 300}}
                  >
              <Content pageName={alias} />    
            </Transition>
          </TransitionGroup>
        </div>
        <Footer />
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        declineButtonText="Verweigern"
        enableDeclineButton
        cookieName="tesicConsultingCookie"
        style={{ background: "rgba(0, 0, 0, 0.7)" }}
        expires={150}
        >
        Um die Webseite optimal gestalten und fortlaufend verbessern zu können, verwenden wir Cookies.{" "}
      </CookieConsent>
      
    </Fragment>
  );

}

export default PageContent;