import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

class TwoListBlock extends Component {
    render() {
        return (

            <section className="two_list_block innerpage-content-blocks">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col">
                            <div className="row mt-3 mb-5">

                                <div className="col-md-6 " >

                                    {this.props.data.headline_1 && 
                                        <Fade bottom>
                                            <h3 className="font-weight-bold text-4 mb-2">
                                                {this.props.data.headline_1}
                                            </h3>
                                        </Fade>
                                    }

                                    {this.props.data.listitems_1 && 
                                        <ul className="list list-icons list-icons-style-3 list-icons-sm">
                                            { this.props.data.listitems_1.map((item, i) => {
                                                return (
                                                    <li key={i}><i className="fas fa-check"></i>
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    }

                                </div>

                                <div className="col-md-6" >

                                    {this.props.data.headline_2 && 
                                        <Fade bottom>
                                            <h3 className="font-weight-bold text-4 mb-2">
                                                {this.props.data.headline_2}
                                            </h3>
                                        </Fade>
                                    }


                                    {this.props.data.listitems_2 && 
                                        <ul className="list list-icons list-icons-style-3 list-icons-sm">
                                            { this.props.data.listitems_2.map((item, i) => {
                                                return (
                                                    <li key={i}><i className="fas fa-check"></i>
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TwoListBlock;