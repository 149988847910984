import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect } from 'react-router';
import "./App.css";

import "../node_modules/jquery/dist/jquery.min.js";

import './assets/vendor/bootstrap/css/bootstrap.min.css';
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

import './assets/vendor/fontawesome-free/css/all.min.css';
import './assets/vendor/animate/animate.compat.css';
import './assets/vendor/simple-line-icons/css/simple-line-icons.min.css';
import './assets/vendor/owl.carousel/assets/owl.carousel.min.css';
import './assets/vendor/owl.carousel/assets/owl.theme.default.min.css';
import './assets/vendor/magnific-popup/magnific-popup.min.css';

import './assets/css/modal-video.min.css';

import './assets/css/theme.css';
import './assets/css/theme-elements.css';
import './assets/css/theme-blog.css';
import './assets/css/theme-shop.css';

import './assets/css/style.css';
import './assets/css/main.css';

import PageContent from './components/layout/PageContent';
import NotFound from './components/layout/NotFound';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path="/404" component={NotFound} />
        <Route path="/:alias" component={PageContent} />
      </Switch>
    </Router>
  );
};

export default App;