import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import PageBanner1 from '../../assets/page-banner-1.svg';
import PageBanner2 from '../../assets/page-banner-2.svg';

class PageBanner extends Component {

    render() {

        return (
            <div>
                
                <div className="section page-banner innerpage-header overlay overlay-show overlay-op-3 section-with-shape-divider section-parallax bg-tertiary border-0 m-0"
                    style={{ background: `url('${this.props.data.image_1_path}') no-repeat center top`, WebkitBackgroundSize: 'cover',  MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', height: "80vh"}}
                    >
                        <div className="shape-divider shape-divider-bottom" >
                            <img src={PageBanner1} alt="Tesic Consulting" />
                        </div>
                        <div className="container position-relative z-index-1 pb-5 my-5">
                            <div>
                                <div className="custom-svg-stars clean-animation-1">
                                    <img src={PageBanner2} alt="Tesic Consulting" />
                                </div>
                            </div>
                            <div className="row justify-content-center justify-content-lg-start pb-5 mb-5">
                                <div className="col-md-10 col-lg-6 text-center text-lg-right pr-lg-4 pb-5 mb-5">
                                    <Fade bottom>
                                        <h1 className="text-color-light font-weight-medium line-height-4 text-10 mb-2">{this.props.data.headline_1}
                                            {this.props.data.headline_2 && <span className="font-weight-bold custom-highlight-1 ws-nowrap p-1 custom-highlight-1-primary customHighlightAnim customHighlightAnimDelay"> {this.props.data.headline_2} </span> } {this.props.data.headline_3}
                                        </h1>
                                        <div className="spacer pt-5 my-5"></div>
                                        <div className="spacer pt-5 my-5"></div>
                                        <div className="spacer pt-5 my-5"></div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                </div>
               
                <section className="page-header innerpage-breadcrumbs page-header-modern page-header-lg bg-tertiary border-0 my-0">
                    <div className="container my-3">
                        <div className="row">
                            <Fade bottom>
                                <div className="col-md-12 align-self-center p-static order-2 text-center">
                                    <h1 className="font-weight-bold text-10">{this.props.pageName}</h1>
                                </div>
                            </Fade>
                            <Fade bottom>
                                <div className="col-md-12 align-self-center order-1">
                                    <ul className="breadcrumb breadcrumb-light d-block text-center">
                                        <li><a href="/home">Home</a></li>
                                        <li className="active">{this.props.pageName}</li>
                                    </ul>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </section>
                
            </div>
        );

    }
}

export default PageBanner;