import React, { Component, useState } from 'react';
import Fade from 'react-reveal/Fade';

import ModalVideo from 'react-modal-video'
import Tooltip from 'react-tooltip-lite';

const VideoBlock = (props) => {

    const [isOpen, setOpen] = useState(false)

    return (
        <section className="home-youtube-section">
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={props.data.youtube_link} onClose={() => setOpen(false)} />
            <div className="row mt-lg-5">
                <div className="col-lg-12">

                    {props.data.youtube_link ?
                    
                        <Tooltip content={props.data.link_text_1} >
                            <a className="btn-primary" onClick={()=> setOpen(true)}>
                            <div className="thumb-info thumb-info-floating-caption thumb-info-hide-wrapper-bg" data-title={`<i style='color:#FF0000' className='fab fa-youtube'></i> ${props.data.link_text_1}`}>
                                <div className="thumb-info-wrapper">
                                    { props.data.image_1_path &&
                                        <img src={props.data.image_1_path} className="img-fluid" alt="Firmanvideo Tesic Consulting" />
                                    }
                                </div>
                            </div>
                            </a>
                        </Tooltip>

                    :

                    <div className="thumb-info thumb-info-floating-caption thumb-info-hide-wrapper-bg" data-title={`<i style='color:#FF0000' className='fab fa-youtube'></i> ${props.data.link_text_1}`}>
                        <div className="thumb-info-wrapper">
                            { props.data.image_1_path &&
                                <img src={props.data.image_1_path} className="img-fluid" alt="Firmanvideo Tesic Consulting" />
                            }
                        </div>
                    </div>

                    }

                </div>		
            </div>
        </section>
    );
   
}

export default VideoBlock;