import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import api from '../data/api'

import BannerForm from '../sections/BannerForm';
import AboutBlock from '../sections/AboutBlock';
import VideoBlock from '../sections/VideoBlock';
import ThreeTextBlock from '../sections/ThreeTextBlock';
import ThreeImageTextBlock from '../sections/ThreeImageTextBlock';
import BackgroundBannerBlock from '../sections/BackgroundBannerBlock';
import ImageBannerBlock from '../sections/ImageBannerBlock';
import PageBanner from '../sections/PageBanner';
import TwoListBlock from '../sections/TwoListBlock';
import IconTextBlock from '../sections/IconTextBlock';
import ThreeListBlock from '../sections/ThreeListBlock';
import ResourcesContent from '../sections/ResourcesContent';

import ContactForm from '../sections/ContactForm';

const Content = (props) => {


    const [isLoading, setIsLoading] = useState(true);
    const [pageData, setPageData] = useState();
    const [noPages, setNoPages] = useState(false);

    useEffect(() => {

        window.scrollTo(2, 2);

        const fetchPageData = async () => {
            api.getPageDetails(props.pageName)
            .then((response)=>{
                setPageData(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                setNoPages(true);
                console.log("mmade error", error);
            })
        }
        fetchPageData();
        
    }, [props.pageName]);

    if(noPages) {
        return (
            <Redirect to="/404" />
        );
    };

    if(!isLoading) {
        
        return (

            <div>
            
                { pageData.articles.main.map((item, index) => { //Map through pageData.articles.main
                    if(item.published) { //Check if its a published element
                        return (
                            <div key={index}>
                                { item.content.map((contentItem, contentIndex) => {
                                    return (
                                        <div key={contentIndex}>
                                            {
                                                {
                                                    banner_form: <BannerForm data={contentItem} />,
                                                    about_block: <AboutBlock data={contentItem} />,
                                                    video_block: <VideoBlock data={contentItem} />,
                                                    three_text_block: <ThreeTextBlock data={contentItem} />,
                                                    three_image_text_block: <ThreeImageTextBlock data={contentItem} />,
                                                    background_banner_block: <BackgroundBannerBlock data={contentItem} />,
                                                    image_banner_block: <ImageBannerBlock data={contentItem} />,
                                                    page_banner: <PageBanner data={contentItem} pageName={pageData.title} />,
                                                    two_list_block: <TwoListBlock data={contentItem} />,
                                                    icon_text_block: <IconTextBlock data={contentItem} />,
                                                    three_list_block: <ThreeListBlock data={contentItem} />,
                                                    resources_content: <ResourcesContent data={contentItem} />,
                                                    contact_form: <ContactForm data={contentItem} />

                                                }[contentItem.type]
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        );

                    }
                })}

            </div>
            
        );

    } else {

        return(
            <div>
                
            </div>
        )

    }

}

export default Content;