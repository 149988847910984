import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import ImageBanner1 from '../../assets/image-banner-1.svg';
import ImageBanner2 from '../../assets/image-banner-2.svg';

class ImageBannerBlock extends Component {
    render() {
        return (
            <section className="section image-banner section-with-shape-divider overlay overlay-show overlay-op-4 border-0 m-0" style={{height: "80vh", backgroundImage:`url(${this.props.data.image_1_path})`, backgroundSize: "cover", backgroundPosition: "top"}} >

                <div className="shape-divider">
                    <img src={ImageBanner1} alt="Tesic Consulting" />
                </div>

                <div className="shape-divider shape-divider-bottom">
                    <img src={ImageBanner2} alt="Tesic Consulting" />
                </div>

                <div className="container pt-9 pb-5 mt-5 mb-3">
                    <div className="row justify-content-center py-5 my-4">
                        <div className="col-lg-9 col-xl-7 text-center px-lg-4">
                            { ((this.props.data.headline_1) || (this.props.data.headline_2) || (this.props.data.headline_3)) &&
                                <Fade bottom>
                                    <h2 className="text-color-light font-weight-medium text-11 line-height-5 mb-2">{this.props.data.headline_1} {this.props.data.headline_2 && <span className="font-weight-bold custom-highlight-1 ws-nowrap custom-highlight-1-primary p-2">{this.props.data.headline_2}</span>}
                                    <br/>{this.props.data.headline_3}</h2>
                                </Fade>
                            }
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default ImageBannerBlock;