import React, {Fragment} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Header from './Header';
import Footer from './Footer';

import image1 from '../../img/404_1.jpg';
import image2 from '../../img/404_2.jpg';

const NotFound = () => {
    return (
        <Fragment>
            <div className="body">
                <Header />
                    <div role="main" className="main mb-5 mt-5">
                        <Container>
                            <Row>
                                <Col>
                                    <h1>“Fehler 404 – Seite nicht gefunden”</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <img src={image1} alt="Tesic Consulting" />
                                </Col>
                                <Col lg={6}>
                                    <img src={image2} alt="Tesic Consulting" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pt-5">
                                    <h2>
                                        Bitte kontaktieren Sie uns
                                        <a href="mailto:sebi.tesic@bluewin.ch" target="_blank" rel="noreferrer"  class="boxed-bg-hover"> sebi.tesic@bluewin.ch</a> |  
                                        <a href="tel:0041792562039" target="_blank" rel="noreferrer"  class=""> +41 9 256 20 39</a>
                                    </h2>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                <Footer />
            </div>
        </Fragment>
    );
}

export default NotFound;

