import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import BarTop1 from '../../assets/bar-top-1.svg';
import BarBottom2 from '../../assets/bar-bottom-2.svg';



class ThreeTextBlock extends Component {
    render() {
        return (

            <section className="section home-three-block section-with-shape-divider bg-tertiary border-0 m-0">

                <div className="shape-divider" >
                    <img src={BarTop1} alt="Tesic Consulting" />
                </div>
                <div className="shape-divider shape-divider-bottom">
                    <img src={BarBottom2} alt="Tesic Consulting" />
                </div>
                <div className="container pt-5 pb-5 mt-4">
                    <div className="row align-items-end pb-0 pt-5 mb-5 mb-lg-4">
                        <div className="col-lg-12 pt-5 mb-4 mb-lg-0">
                            <div className="d-flex align-items-center mb-2">
                                <Fade bottom>
                                    <span className="custom-line"></span>
                                    <div className="overflow-hidden ml-3">
                                        <h2 className="text-color-light font-weight-semibold line-height-3 text-4 mb-0">{this.props.data.headline_1}</h2>
                                    </div>
                                </Fade>
                            </div>
                            
                            <h3 className="text-color-light font-weight-bold text-transform-none text-8 mb-3 pb-1">
                                <Fade bottom>
                                    {this.props.data.headline_2}
                                </Fade>
                            </h3>
                        </div>
                        
                    </div>

                    <div className="row mt-2 mb-5 pb-5">
                        <div className="col-lg-4 pr-lg-0 mb-4 mb-lg-0">
                            <h2 className="text-color-light font-weight-bold line-height-2 text-8 mb-3">
                                <Fade bottom>
                                    {this.props.data.headline_3}
                                </Fade>
                            </h2>
                            
                            <Fade bottom>
                                <div className="text-color-light opacity-7 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_1}}>
                                </div>
                            </Fade>
                            
                        </div>
                        <div className="col-lg-4 pr-lg-0 mb-4 mb-lg-0">
                            
                            <h2 className="text-color-light font-weight-bold line-height-2 text-8 mb-3">
                                <Fade bottom>
                                    {this.props.data.headline_4}
                                </Fade>
                            </h2>
                            
                            <Fade bottom>
                                <div className="text-color-light opacity-7 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_2}}>
                                </div>
                            </Fade>
                            
                        </div>
                        <div className="col-lg-4 pr-lg-0 mb-4 mb-lg-0">
                        
                            <h2 className="text-color-light font-weight-bold line-height-2 text-8 mb-3">
                                <Fade bottom>
                                    {this.props.data.headline_5}
                                </Fade>
                            </h2>
                            
                            <Fade bottom>
                                <div className="text-color-light opacity-7 mb-0" dangerouslySetInnerHTML={{__html: this.props.data.text_3}}>
                                </div>
                            </Fade>

                        </div>
                    </div>
                </div>
            </section>



        );
    }
}

export default ThreeTextBlock;